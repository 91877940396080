@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./debug.scss";

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -msbox-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 100%;
}

#root {
  overflow-x: hidden;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

a {
  text-decoration: none;
}

input {
  outline: 0
}

// for design purpose.
div {
  //border: 1px dotted pink;
}

.multiline {
  white-space: pre-line;
}

.slick-list {
  padding: 0 0 0 0;

  *:focus {
    outline: 0;
    outline: none;
  }
}

.slide-slick {}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}